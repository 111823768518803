define("ember-redux/reducers/index", ["exports", "@ember/debug"], function (_exports, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  // please write your own index.js that exports legit reducers
  //
  // import { combineReducers } from 'redux';
  // const foobar = (state, action) => {
  //   //reducer code here
  // };
  //
  // export default combineReducers({
  //   foobar
  // })
  function _default(state) {
    (false && (0, _debug.warn)("You haven't created a reducer yet. Place the root reducer in \"app/reducers/index.js\"", null, {
      id: 'ember-redux.default-reducer'
    }));
    return state;
  }
});