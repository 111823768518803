define("@zestia/ember-select-box/utils/shared/api", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = buildAPI;
  var isSealed = Object.isSealed,
      seal = Object.seal;

  function buildAPI(component, keys) {
    if (component.isDestroying) {
      return;
    }

    keys.forEach(function (key) {
      component.stableAPI[key] = component[key];
    });

    if (!isSealed(component.stableAPI)) {
      seal(component.stableAPI);
    }

    return component.stableAPI;
  }
});