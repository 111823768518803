define("ember-gestures/mixins/recognizers", ["exports", "@ember/service", "@ember/object", "@ember/application", "@ember/object/mixin", "@ember/object/evented"], function (_exports, _service, _object, _application, _mixin, _evented) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    '-gestures': (0, _service.inject)('-gestures'),
    __fastboot: (0, _object.computed)(function () {
      var owner = (0, _application.getOwner)(this);
      return owner.lookup('service:fastboot');
    }),
    recognizers: null,
    managerOptions: null,
    __instance: null,
    __manager: function __manager() {
      var instance = this.get('__instance');

      if (instance) {
        return instance;
      }

      var opts = this.get('managerOptions') || {
        domEvents: true
      };
      opts.useCapture = this.get('-gestures.useCapture');
      instance = new Hammer.Manager(this.element, opts);
      this.set('__instance', instance);
      return instance;
    },
    __setupRecognizers: (0, _evented.on)('didInsertElement', function () {
      var _this = this;

      if (this.get('__fastboot.isFastBoot')) {
        return;
      }

      var promise = this.get('recognizers');

      if (promise) {
        promise.then(function (recognizers) {
          if (_this.get('isDestroyed')) {
            return;
          }

          var manager = _this.__manager(); // sort the recognizers


          for (var i = 0; i < recognizers.length; i++) {
            var r = recognizers[i];
            var currentIndex = i;

            if (r.exclude.length) {
              for (var j = 0; j < r.exclude.length; j++) {
                var newIndex = recognizers.indexOf(r.exclude[j]);

                if (newIndex > 0 && currentIndex < newIndex) {
                  recognizers.splice(currentIndex, 1);
                  recognizers.splice(newIndex, 0, r);
                  currentIndex = newIndex;
                }
              }
            }
          }

          for (var _i = 0; _i < recognizers.length; _i++) {
            manager.add(recognizers[_i]);
          }
        });
      }
    }),
    __teardownRecognizers: (0, _evented.on)('willDestroyElement', function () {
      var instance = this.get('__instance');

      if (instance) {
        //instance.off();
        instance.destroy();
        this.set('__instance', null);
      }
    }),
    init: function init() {
      this._super(); // setup recognizers


      var recognizers = this.get('recognizers');

      if (recognizers) {
        this.set('recognizers', this.get('-gestures').retrieve(recognizers.split(' ')));
      }
    }
  });

  _exports.default = _default;
});