define("@zestia/ember-select-box/utils/select-box/focus", ["exports", "@ember/runloop"], function (_exports, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.focusOut = focusOut;

  function focusOut(selectBox, e) {
    (0, _runloop.scheduleOnce)('afterRender', selectBox, focusOutSettled, selectBox, e);
  }

  function focusOutSettled(selectBox, e) {
    if (selectBox.isDestroying) {
      return;
    }

    var focusInside = selectBox.element.contains(e.relatedTarget) || selectBox.element.contains(document.activeElement);

    if (focusInside) {
      return;
    }

    focusedOut(selectBox, e);
  }

  function focusedOut(selectBox, e) {
    var _selectBox$args$onFoc, _selectBox$args;

    (_selectBox$args$onFoc = (_selectBox$args = selectBox.args).onFocusLeave) === null || _selectBox$args$onFoc === void 0 ? void 0 : _selectBox$args$onFoc.call(_selectBox$args, e, selectBox.api);
  }
});