define("@zestia/ember-select-box/utils/select-box/keyboard", ["exports", "@zestia/ember-select-box/utils/select-box/option/select", "@ember/string", "@zestia/ember-select-box/utils/general/keyboard"], function (_exports, _select, _string, _keyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.keyPress = keyPress;
  _exports.keyDown = keyDown;

  function keyPress(selectBox, e) {
    pressedKey(selectBox, e);
  }

  function keyDown(selectBox, e) {
    var keyName = (0, _keyboard.getKeyName)(e);

    if (keyName) {
      keyedDown(selectBox, (0, _string.capitalize)(keyName), e);
    }

    if (keyName === 'enter') {
      keyedDownEnter(selectBox, e);
    }

    if (keyName === 'space') {
      keyedDownSpace(selectBox, e);
    }
  }

  function pressedKey(selectBox, e) {
    var _selectBox$args$onPre, _selectBox$args;

    (_selectBox$args$onPre = (_selectBox$args = selectBox.args).onPressKey) === null || _selectBox$args$onPre === void 0 ? void 0 : _selectBox$args$onPre.call(_selectBox$args, e, selectBox.api);
  }

  function keyedDown(selectBox, key, e) {
    var _selectBox$args$, _selectBox$args2;

    (_selectBox$args$ = (_selectBox$args2 = selectBox.args)["onPress".concat(key)]) === null || _selectBox$args$ === void 0 ? void 0 : _selectBox$args$.call(_selectBox$args2, e, selectBox.api);
  }

  function keyedDownSpace(selectBox) {
    if (!selectBox.activeOption || !!selectBox.charState) {
      return;
    }

    if (selectBox.input) {
      return;
    }

    (0, _select._selectOption)(selectBox.activeOption);
  }

  function keyedDownEnter(selectBox, e) {
    if (!selectBox.activeOption) {
      return;
    }

    if (selectBox.input) {
      e.preventDefault();
    }

    (0, _select._selectOption)(selectBox.activeOption);
  }
});