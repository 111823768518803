define("ember-redux/index", ["exports", "ember-redux/connect", "ember-redux/route"], function (_exports, _connect, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "connect", {
    enumerable: true,
    get: function get() {
      return _connect.default;
    }
  });
  Object.defineProperty(_exports, "route", {
    enumerable: true,
    get: function get() {
      return _route.default;
    }
  });
});