define("@zestia/ember-select-box/utils/select-box/value", ["exports", "@zestia/ember-select-box/utils/shared/value", "@zestia/ember-select-box/utils/select-box/selection"], function (_exports, _value, _selection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.selectValue = selectValue;

  function selectValue(selectBox, value) {
    value = (0, _selection.buildSelection)(selectBox, value);
    return (0, _value.selectValue)(selectBox, value);
  }
});