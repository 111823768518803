define("@zestia/ember-select-box/utils/component/lifecycle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports._insertComponent = _insertComponent;
  _exports._destroyComponent = _destroyComponent;

  function _insertComponent(component) {
    var _component$args$_onIn, _component$args;

    (_component$args$_onIn = (_component$args = component.args)._onInsert) === null || _component$args$_onIn === void 0 ? void 0 : _component$args$_onIn.call(_component$args, component);
  }

  function _destroyComponent(component) {
    var _component$args$_onDe, _component$args2;

    (_component$args$_onDe = (_component$args2 = component.args)._onDestroy) === null || _component$args$_onDe === void 0 ? void 0 : _component$args$_onDe.call(_component$args2, component);
  }
});