define("ember-cli-ifa/services/asset-map", ["exports", "@ember/service", "@ember/object"], function (_exports, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    enabled: false,
    map: (0, _object.computed)(function () {
      return {};
    }),
    prepend: '/',
    fullMap: (0, _object.computed)('map', function () {
      var map = (0, _object.get)(this, 'map');
      var ret = {};
      Object.keys(map).forEach(function (k) {
        var v = map[k];
        ret[k] = v;
        ret[v] = v;
      });
      return ret;
    }),
    resolve: function resolve(name) {
      var fullMap = (0, _object.get)(this, 'fullMap') || {};
      var prepend = (0, _object.get)(this, 'prepend');
      var enabled = (0, _object.get)(this, 'enabled');
      var assetName = enabled ? fullMap[name] || name : name;
      return "".concat(prepend).concat(assetName);
    }
  });

  _exports.default = _default;
});