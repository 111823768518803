define("ember-click-outside/mixins/click-outside", ["exports", "ember-click-outside/mixin", "ember-click-outside/utils"], function (_exports, _mixin, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  (0, _utils.printConsoleMessage)("Importing 'ember-click-outside/mixins/click-outside' is deprecated. Please import from 'ember-click-outside/mixin' instead.");
  var _default = _mixin.default;
  _exports.default = _default;
});