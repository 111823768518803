define("@zestia/ember-select-box/utils/registration/input", ["exports", "@ember/debug"], function (_exports, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.registerInput = registerInput;
  _exports.deregisterInput = deregisterInput;

  function registerInput(selectBox, input) {
    (false && !(!selectBox.input) && (0, _debug.assert)('select-box can only have 1 input', !selectBox.input));
    selectBox.input = input;
  }

  function deregisterInput(selectBox) {
    selectBox.input = null;
  }
});