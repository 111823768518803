define("@zestia/ember-select-box/utils/select-box/input/focus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.focusInput = focusInput;
  _exports.blurInput = blurInput;

  function focusInput(selectBox) {
    if (!selectBox.input) {
      return;
    }

    selectBox.input.element.focus();
  }

  function blurInput(selectBox) {
    if (!selectBox.input) {
      return;
    }

    selectBox.input.element.blur();
  }
});