define("ember-keyboard/utils/get-mouse-name", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getMouseName;

  function getMouseName(buttonCode) {
    if ((0, _utils.isNone)(buttonCode)) return;

    switch (buttonCode) {
      case 0:
        return 'left';

      case 1:
        return 'middle';

      case 2:
        return 'right';
    }
  }
});