define("ember-redux/enhancers/index", ["exports", "redux"], function (_exports, _redux) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var devtools = window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : function (f) {
    return f;
  };

  var _default = (0, _redux.compose)(devtools);

  _exports.default = _default;
});