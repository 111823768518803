define("ember-redux/connect", ["exports", "@ember/component", "ember-redux/es2015-class", "ember-redux/octane-connect"], function (_exports, _component, _es2015Class, _octaneConnect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function isClassicComponent(component) {
    // Not finding any other better way check component is classic or octane
    return component.toString() === '@ember/component' && component.reopenClass;
  }

  var _default = function _default(stateToComputed) {
    var dispatchToActions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {
      return {};
    };
    return function (IncomingComponent) {
      var WrappedComponent = IncomingComponent || _component.default;

      if (isClassicComponent(WrappedComponent)) {
        return (0, _es2015Class.default)(stateToComputed, dispatchToActions, WrappedComponent);
      }

      return (0, _octaneConnect.default)(stateToComputed, dispatchToActions, WrappedComponent);
    };
  };

  _exports.default = _default;
});