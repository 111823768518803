define("ember-table/components/ember-tfoot/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j6mMVf/P",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"wrappedRowArray\"]]],null]],null],null,[[[6,[39,2],null,[[\"rowValue\",\"columns\",\"columnMetaCache\",\"rowMetaCache\",\"canSelect\",\"rowSelectionMode\",\"checkboxSelectionMode\",\"rowsCount\"],[[30,1],[30,0,[\"columns\"]],[30,0,[\"columnMetaCache\"]],[30,0,[\"rowMetaCache\"]],[30,0,[\"canSelect\"]],[30,0,[\"rowSelectionMode\"]],[30,0,[\"checkboxSelectionMode\"]],[30,0,[\"wrappedRowArray\",\"length\"]]]],[[\"default\"],[[[[41,[48,[30,3]],[[[1,\"      \"],[18,3,[[28,[37,6],null,[[\"rowValue\",\"rowMeta\",\"cells\",\"rowSelectionMode\",\"rowsCount\",\"row\"],[[30,2,[\"rowValue\"]],[30,2,[\"rowMeta\"]],[30,2,[\"cells\"]],[30,2,[\"rowSelectionMode\"]],[30,2,[\"rowsCount\"]],[50,\"ember-tr\",0,null,[[\"api\"],[[30,2]]]]]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,8],null,[[\"api\"],[[30,2]]]]],[1,\"\\n\"]],[]]]],[2]]]]]],[1]],null]],[\"rowValue\",\"api\",\"&default\"],false,[\"each\",\"-track-array\",\"-ember-table-private/row-wrapper\",\"if\",\"has-block\",\"yield\",\"hash\",\"component\",\"ember-tr\"]]",
    "moduleName": "ember-table/components/ember-tfoot/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});