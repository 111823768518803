define("@zestia/ember-select-box/utils/select-box/toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.open = open;
  _exports.close = close;
  _exports.toggle = toggle;

  function open(selectBox) {
    if (selectBox.isOpen) {
      return;
    }

    selectBox.isOpen = true;
    opened(selectBox);
  }

  function close(selectBox) {
    if (!selectBox.isOpen) {
      return;
    }

    selectBox.isOpen = false;
    closed(selectBox);
  }

  function opened(selectBox) {
    var _selectBox$args$onOpe, _selectBox$args;

    (_selectBox$args$onOpe = (_selectBox$args = selectBox.args).onOpen) === null || _selectBox$args$onOpe === void 0 ? void 0 : _selectBox$args$onOpe.call(_selectBox$args, selectBox.api);
  }

  function closed(selectBox) {
    var _selectBox$args$onClo, _selectBox$args2;

    (_selectBox$args$onClo = (_selectBox$args2 = selectBox.args).onClose) === null || _selectBox$args$onClo === void 0 ? void 0 : _selectBox$args$onClo.call(_selectBox$args2, selectBox.api);
  }

  function toggle(selectBox) {
    if (selectBox.isOpen) {
      close(selectBox);
    } else {
      open(selectBox);
    }
  }
});