define("@zestia/ember-select-box/utils/select-box/input/value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setInputValue = setInputValue;

  function setInputValue(selectBox, value) {
    if (!selectBox.input) {
      return;
    }

    selectBox.input.element.value = value;
  }
});