define("ember-redux/services/redux", ["exports", "@ember/object", "@ember/debug", "@ember/array", "@ember/service", "redux", "ember-redux/reducers/index", "ember-redux/enhancers/index", "ember-redux/middleware/index"], function (_exports, _object, _debug, _array, _service, _redux, _index, _index2, _index3) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  // Handle "classic" middleware exports (i.e. an array), as well as the hash option
  var extractMiddlewareConfig = function extractMiddlewareConfig(mc) {
    (false && !((0, _array.isArray)(mc) || mc.middleware) && (0, _debug.assert)('Middleware must either be an array, or a hash containing a `middleware` property', (0, _array.isArray)(mc) || mc.middleware));
    return (0, _array.isArray)(mc) ? {
      middleware: mc
    } : mc;
  };

  var createStore = _redux.default.createStore,
      applyMiddleware = _redux.default.applyMiddleware,
      compose = _redux.default.compose;

  var makeStoreInstance = function makeStoreInstance(_ref) {
    var middlewares = _ref.middlewares,
        reducers = _ref.reducers,
        enhancers = _ref.enhancers;

    var _extractMiddlewareCon = extractMiddlewareConfig(middlewares),
        middleware = _extractMiddlewareCon.middleware,
        _extractMiddlewareCon2 = _extractMiddlewareCon.setup,
        setup = _extractMiddlewareCon2 === void 0 ? function () {} : _extractMiddlewareCon2;

    var createStoreWithMiddleware = compose(applyMiddleware.apply(void 0, _toConsumableArray(middleware)), enhancers)(createStore);
    var store = createStoreWithMiddleware(reducers);
    setup(store);
    return store;
  };

  var _default = _service.default.extend({
    middlewares: _index3.default,
    reducers: _index.default,
    enhancers: _index2.default,
    makeStoreInstance: makeStoreInstance,
    init: function init() {
      var enhancers = (0, _object.get)(this, 'enhancers');
      var reducers = (0, _object.get)(this, 'reducers');
      var middlewares = (0, _object.get)(this, 'middlewares');
      this.store = this.makeStoreInstance({
        middlewares: middlewares,
        reducers: reducers,
        enhancers: enhancers
      });

      this._super.apply(this, arguments);
    },
    getState: function getState() {
      return this.store.getState();
    },
    dispatch: function dispatch(action) {
      return this.store.dispatch(action);
    },
    subscribe: function subscribe(func) {
      return this.store.subscribe(func);
    },
    replaceReducer: function replaceReducer(nextReducer) {
      return this.store.replaceReducer(nextReducer);
    }
  });

  _exports.default = _default;
});