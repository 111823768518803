define("ember-table/components/ember-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uqfkHMoM",
    "block": "[[[10,0],[14,\"data-simplebar\",\"\"],[14,0,\"ember-table-overflow\"],[15,1,[29,[[30,0,[\"elementId\"]],\"-overflow\"]]],[12],[1,\"\\n  \"],[10,\"table\"],[12],[1,\"\\n    \"],[18,1,[[28,[37,1],null,[[\"api\",\"head\",\"body\",\"foot\"],[[30,0,[\"api\"]],[50,\"ember-thead\",0,null,[[\"api\"],[[30,0,[\"api\"]]]]],[50,\"ember-tbody\",0,null,[[\"api\"],[[30,0,[\"api\"]]]]],[50,\"ember-tfoot\",0,null,[[\"api\"],[[30,0,[\"api\"]]]]]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[1,[28,[35,3],null,[[\"api\"],[[30,0,[\"api\"]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"-ember-table-private/scroll-indicators\"]]",
    "moduleName": "ember-table/components/ember-table/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});