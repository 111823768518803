define("ember-file-upload/file", ["exports", "@ember/test", "@ember/debug", "@ember/object", "ember-file-upload/system/file-reader", "ember-file-upload/system/http-request", "rsvp", "ember-file-upload/system/uuid"], function (_exports, _test, _debug, _object, _fileReader, _httpRequest, _rsvp, _uuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var reads = _object.computed.reads;

  function normalizeOptions(file, url, options) {
    if (_typeof(url) === 'object') {
      options = url;
      url = null;
    }

    options = options || {};
    options.url = options.url || url;
    options.method = options.method || 'POST';
    options.accepts = options.accepts || ['application/json', 'text/javascript'];

    if (!Object.prototype.hasOwnProperty.call(options, 'contentType')) {
      options.contentType = file.type;
    }

    options.headers = options.headers || {};
    options.data = options.data || {};
    options.fileKey = options.fileKey || 'file';

    if (options.headers.Accept == null) {
      if (!Array.isArray(options.accepts)) {
        options.accepts = [options.accepts];
      }

      options.headers.Accept = options.accepts.join(',');
    } // Set Content-Type in the data payload
    // instead of the headers, since the header
    // for Content-Type will always be multipart/form-data


    if (options.contentType) {
      options.data['Content-Type'] = options.contentType;
    }

    options.data[options.fileKey] = file.blob;
    options.withCredentials = options.withCredentials || false;
    return options;
  }

  function _upload(file, url, opts, uploadFn) {
    if (['queued', 'failed', 'timed_out', 'aborted'].indexOf(file.state) === -1) {
      (false && !(false) && (0, _debug.assert)("The file ".concat(file.id, " is in the state \"").concat(file.state, "\" and cannot be requeued.")));
    }

    var options = normalizeOptions(file, url, opts);
    var request = new _httpRequest.default({
      withCredentials: options.withCredentials,
      label: "".concat(options.method, " ").concat(file.name, " to ").concat(options.url)
    });
    request.open(options.method, options.url);
    Object.keys(options.headers).forEach(function (key) {
      request.setRequestHeader(key, options.headers[key]);
    });

    if (options.timeout) {
      request.timeout = options.timeout;
    }

    request.onprogress = function (evt) {
      if (!evt.lengthComputable || evt.total === 0) return;
      (0, _object.set)(file, 'loaded', evt.loaded);
      (0, _object.set)(file, 'size', evt.total);
      (0, _object.set)(file, 'progress', evt.loaded / evt.total * 100);
    };

    request.ontimeout = function () {
      (0, _object.set)(file, 'state', 'timed_out');
    };

    request.onabort = function () {
      (0, _object.set)(file, 'state', 'aborted');
    };

    (0, _object.set)(file, 'state', 'uploading'); // Increment for Ember.Test

    inflightRequests++;
    var uploadPromise = uploadFn(request, options);
    uploadPromise = uploadPromise.then(function (result) {
      (0, _object.set)(file, 'state', 'uploaded');
      return result;
    }, function (error) {
      (0, _object.set)(file, 'state', 'failed');
      return _rsvp.default.reject(error);
    }).finally(function () {
      var queue = file.queue;

      if (queue) {
        file.queue.flush();
      } // Decrement for Ember.Test


      inflightRequests--;
    });
    return uploadPromise;
  }

  var inflightRequests = 0;

  if (false
  /* DEBUG */
  ) {
    (0, _test.registerWaiter)(null, function () {
      return inflightRequests === 0;
    });
  }
  /**
    Files provide a uniform interface for interacting
    with data that can be uploaded or read.
  
    @class File
    @extends Ember.Object
   */


  var _default = _object.default.extend({
    init: function init() {
      this._super();

      Object.defineProperty(this, 'id', {
        writeable: false,
        enumerable: true,
        value: "file-".concat((0, _uuid.default)())
      });
    },

    /**
      A unique id generated for this file.
       @property id
      @type {String}
      @readonly
     */
    id: null,

    /**
      The file name.
       @accessor name
      @type {String}
     */
    name: (0, _object.computed)('blob.name', {
      get: function get() {
        return this.blob.name;
      },
      set: function set(_, name) {
        return name;
      }
    }),

    /**
      The size of the file in bytes.
       @accessor size
      @type {Number}
      @readonly
     */
    size: reads('blob.size'),

    /**
      The MIME type of the file.
       For a image file this may be `image/png`.
       @accessor type
      @type {String}
      @readonly
     */
    type: reads('blob.type'),

    /**
      Returns the appropriate file extension of
      the file according to the type
       @accessor extension
      @type {String}
      @readonly
     */
    extension: (0, _object.computed)('type', {
      get: function get() {
        return this.type.split('/').slice(-1)[0];
      }
    }),

    /**
      @accessor loaded
      @type {Number}
      @default 0
      @readonly
     */
    loaded: 0,

    /**
      @accessor progress
      @type {Number}
      @default 0
      @readonly
     */
    progress: 0,

    /**
      The current state that the file is in.
      One of:
       - `queued`
      - `uploading`
      - `timed_out`
      - `aborted`
      - `uploaded`
      - `failed`
       Implementers should call flush() on the file's queue after mutating this property.
       @accessor state
      @type {String}
      @default 'queued'
      @readonly
     */
    state: 'queued',

    /**
      The source of the file. This is useful
      for applications that want to gather
      analytics about how users upload their
      content.
       This property can be one of the following:
       - `browse`
      - `drag-and-drop`
      - `web`
      - `data-url`
      - `blob`
       `browse` is the source when the file is created
      using the native file picker.
       `drag-and-drop` is the source when the file was
      created using drag and drop from their desktop.
       `web` is the source when the file was created
      by dragging the file from another webpage.
       `data-url` is the source when the file is created
      from a data URL using the `fromDataURL` method for
      files. This usually means that the file was created
      manually by the developer on behalf of the user.
       `blob` is the source when the file is created
      from a blob using the `fromBlob` method for
      files. This usually means that the file was created
      manually by the developer.
       @accessor source
      @type {String}
      @default ''
      @readonly
     */
    source: '',

    /**
     * Upload file with `application/octet-stream` content type.
     *
     * @method uploadBinary
     * @param {String} url Your server endpoint where to upload the file
     * @param {hash} opts
     * @return {Promise}
     */
    uploadBinary: function uploadBinary(url, opts) {
      var _this = this;

      opts.contentType = 'application/octet-stream';
      return _upload(this, url, opts, function (request) {
        return request.send(_this.blob);
      });
    },

    /**
     * Upload file to your server
     *
     * @method upload
     * @param {String} url Your server endpoint where to upload the file
     * @param {Hash} opts { fileKey: string, data: { key: string } }
     * @return {Promise}
     */
    upload: function upload(url, opts) {
      var _this2 = this;

      return _upload(this, url, opts, function (request, options) {
        // Build the form
        var form = new FormData();
        Object.keys(options.data).forEach(function (key) {
          if (key === options.fileKey) {
            form.append(key, options.data[key], _this2.name);
          } else {
            form.append(key, options.data[key]);
          }
        });
        return request.send(form);
      });
    },

    /**
     * Resolves with Blob as ArrayBuffer
     *
     * @method readAsArrayBuffer
     * @return {Promise}
     */
    readAsArrayBuffer: function readAsArrayBuffer() {
      var reader = new _fileReader.default({
        label: "Read ".concat(this.name, " as an ArrayBuffer")
      });
      return reader.readAsArrayBuffer(this.blob);
    },

    /**
     * Resolves with Blob as DataURL
     *
     * @method readAsDataURL
     * @return {Promise}
     */
    readAsDataURL: function readAsDataURL() {
      var reader = new _fileReader.default({
        label: "Read ".concat(this.name, " as a Data URI")
      });
      return reader.readAsDataURL(this.blob);
    },

    /**
     * Resolves with Blob as binary string
     *
     * @method readAsBinaryString
     * @return {Promise}
     */
    readAsBinaryString: function readAsBinaryString() {
      var reader = new _fileReader.default({
        label: "Read ".concat(this.name, " as a binary string")
      });
      return reader.readAsBinaryString(this.blob);
    },

    /**
     * Resolves with Blob as plain text
     *
     * @method readAsText
     * @return {Promise}
     */
    readAsText: function readAsText() {
      var reader = new _fileReader.default({
        label: "Read ".concat(this.name, " as text")
      });
      return reader.readAsText(this.blob);
    }
  }).reopenClass({
    /**
      Creates a file object that can be read or uploaded to a
      server from a Blob object.
       @static
      @method fromBlob
      @param {Blob} blob The blob to create the file from.
      @param {String} [source] The source that created the blob.
      @return {File} A file object
     */
    fromBlob: function fromBlob(blob) {
      var source = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'blob';
      var file = this.create();
      Object.defineProperty(file, 'blob', {
        writeable: false,
        enumerable: false,
        value: blob
      });
      Object.defineProperty(file, 'source', {
        writeable: false,
        value: source
      });
      return file;
    },

    /**
      Creates a file object that can be read or uploaded to a
      server from a data URL.
       @static
      @method fromDataURL
      @param {String} dataURL The data URL to create the file from.
      @param {String} [source] The source of the data URL.
      @return {File} A file object
     */
    fromDataURL: function fromDataURL(dataURL) {
      var source = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'data-url';

      var _dataURL$split = dataURL.split(','),
          _dataURL$split2 = _slicedToArray(_dataURL$split, 2),
          typeInfo = _dataURL$split2[0],
          base64String = _dataURL$split2[1];

      var mimeType = typeInfo.match(/:(.*?);/)[1];
      var binaryString = atob(base64String);
      var binaryData = new Uint8Array(binaryString.length);

      for (var i = 0, len = binaryString.length; i < len; i++) {
        binaryData[i] = binaryString.charCodeAt(i);
      }

      var blob = new Blob([binaryData], {
        type: mimeType
      });
      return this.fromBlob(blob, source);
    }
  });

  _exports.default = _default;
});