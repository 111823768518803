define("@zestia/ember-select-box/utils/component/value", ["exports", "rsvp"], function (_exports, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.receiveValue = receiveValue;
  _exports.resolveValue = resolveValue;

  function receiveValue(component) {
    resolveValue(component, component.args.value);
  }

  function resolveValue(component, value, postProcess) {
    var valueId = startResolvingValue(component, value);
    return (0, _rsvp.resolve)(value).then(function (value) {
      value = processValue(component, postProcess, value);
      handleValue(component, valueId, value, false);
    }).catch(function (error) {
      handleValue(component, valueId, error, true);
    });
  }

  function startResolvingValue(component, value) {
    component.value = value;
    component.isPending = true;
    component.isRejected = false;
    component.isFulfilled = false;
    component.isSettled = false;
    return ++component.valueId;
  }

  function handleValue(component, valueId, value, erred) {
    if (valueId < component.valueId) {
      return;
    }

    finishResolvingValue(component, value, erred);
  }

  function finishResolvingValue(component, value, erred) {
    component.value = value;
    component.isPending = false;
    component.isRejected = erred;
    component.isFulfilled = !erred;
    component.isSettled = true;
  }

  function processValue(component, fn, value) {
    if (typeof fn === 'function') {
      return fn(component, value);
    }

    return value;
  }
});