define("@zestia/ember-select-box/utils/select-box/input/keyboard", ["exports", "@zestia/ember-select-box/utils/general/keyboard"], function (_exports, _keyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.input = input;
  _exports.keyDown = keyDown;

  function input(input, e) {
    var text = input.element.value;

    if (!text) {
      clearedInput(input);
    }

    _input(input, text);
  }

  function _input(input, text) {
    input.args._onInput(text);
  }

  function clearedInput(input) {
    var _input$args$onClear, _input$args;

    (_input$args$onClear = (_input$args = input.args).onClear) === null || _input$args$onClear === void 0 ? void 0 : _input$args$onClear.call(_input$args, input.args.selectBox.api);
  }

  function deletedText(input) {
    var _input$args$onDelete, _input$args2;

    (_input$args$onDelete = (_input$args2 = input.args).onDelete) === null || _input$args$onDelete === void 0 ? void 0 : _input$args$onDelete.call(_input$args2, input.args.selectBox.api);
  }

  function keyDown(input, e) {
    var keyName = (0, _keyboard.getKeyName)(e);

    if (keyName === 'backspace' && !input.element.value) {
      deletedText(input);
    }
  }
});