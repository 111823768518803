define("ember-class-based-modifier/classic", ["exports", "ember-class-based-modifier/-private/modifier-classic"], function (_exports, _modifierClassic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _modifierClassic.default;
    }
  });
});