define("@zestia/ember-select-box/utils/registration/options", ["exports", "@ember/debug"], function (_exports, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.registerOptions = registerOptions;
  _exports.deregisterOptions = deregisterOptions;

  function registerOptions(selectBox, options) {
    (false && !(!selectBox.options) && (0, _debug.assert)('select-box can only have 1 options container', !selectBox.options));
    selectBox.options = options;
  }

  function deregisterOptions(selectBox) {
    selectBox.options = null;
  }
});