define("ember-class-based-modifier/-private/modifier-classic", ["exports", "@ember/object", "@ember/modifier", "ember-class-based-modifier/-private/modifier-manager"], function (_exports, _object, _modifier, _modifierManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ClassBasedModifier = _object.default.extend({
    args: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.element = null;
    },
    didReceiveArguments: function didReceiveArguments() {},
    didUpdateArguments: function didUpdateArguments() {},
    didInstall: function didInstall() {},
    willRemove: function willRemove() {}
  });

  (0, _modifier.setModifierManager)(function () {
    return _modifierManager.default;
  }, ClassBasedModifier);
  var _default = ClassBasedModifier;
  _exports.default = _default;
});