define("ember-sortablejs/components/sortable-js", ["exports", "@ember/component", "sortablejs", "@ember/runloop", "ember-diff-attrs", "ember-sortablejs/templates/components/sortable-js"], function (_exports, _component, _sortablejs, _runloop, _emberDiffAttrs, _sortableJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var freeze = Object.freeze;

  var _default = _component.default.extend({
    layout: _sortableJs.default,
    classNames: ['ember-sortable-js'],
    options: null,
    events: freeze(['onChoose', 'onUnchoose', 'onStart', 'onEnd', 'onAdd', 'onUpdate', 'onSort', 'onRemove', 'onMove', 'onClone', 'onChange', 'scrollFn', 'onSetData', 'setData', 'onFilter']),
    didReceiveAttrs: (0, _emberDiffAttrs.default)('options', function (changedAttrs) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, args);

      if (changedAttrs && changedAttrs.options) {
        var options = changedAttrs.options.pop();

        for (var _i = 0, _Object$entries = Object.entries(options); _i < _Object$entries.length; _i++) {
          var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
              key = _Object$entries$_i[0],
              value = _Object$entries$_i[1];

          this.setOptions(key, value);
        }
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var el = this.element.firstElementChild;
      var defaults = {};
      var options = Object.assign({}, defaults, this.options);
      this.sortable = _sortablejs.default.create(el, options);
      this.setupEventHandlers();
    },
    willDestroyElement: function willDestroyElement() {
      this.sortable.destroy();

      this._super.apply(this, arguments);
    },
    setupEventHandlers: function setupEventHandlers() {
      var _this = this;

      this.events.forEach(function (eventName) {
        var action = _this[eventName];

        if (typeof action === 'function') {
          _this.sortable.option(eventName, (0, _runloop.bind)(_this, 'performExternalAction', eventName));
        }
      });
    },
    performExternalAction: function performExternalAction(actionName) {
      var action = this[actionName];
      action = action === 'onSetData' ? 'setData' : action;

      if (typeof action === 'function') {
        for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
          args[_key2 - 1] = arguments[_key2];
        }

        action.apply(void 0, args.concat([this.sortable]));
      }
    },
    setOptions: function setOptions(option, value) {
      this.sortable.option(option, value);
    }
  });

  _exports.default = _default;
});