define("ember-useragent/initializers/user-agent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /**
   * Ember UserAgent initializer
   *
   * Supports auto injection of the userAgent service app-wide.
   * Generated by the ember-useragent addon.
   */
  function initialize() {
    var application = arguments[1] || arguments[0];
    application.inject('controller', 'userAgent', 'service:userAgent');
    application.inject('component', 'userAgent', 'service:userAgent');
    application.inject('route', 'userAgent', 'service:userAgent');
    application.inject('view', 'userAgent', 'service:userAgent');
  }

  var _default = {
    name: 'user-agent',
    initialize: initialize
  };
  _exports.default = _default;
});