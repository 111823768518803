define("ember-keyboard/mixins/activate-keyboard-on-init", ["exports", "@ember/object/mixin", "@ember/object/evented", "@ember/object", "@ember/debug"], function (_exports, _mixin, _evented, _object, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable ember/no-new-mixins */
  var _default = _mixin.default.create({
    init: function init() {
      (false && !(false) && (0, _debug.deprecate)('`EKOnInitMixin` mixin of ember-keyboard is deprecated. This behavior is now the default when using the @keyResponder decorator.', false, {
        id: 'ember-keyboard.activate-keyboard-on-init-mixin',
        for: 'ember-keyboard',
        since: '6.0.2',
        until: '7.0.0',
        url: 'https://adopted-ember-addons.github.io/ember-keyboard/deprecations#activate-keyboard-on-init-mixin'
      }));
      return this._super.apply(this, arguments);
    },
    activateKeyboardWhenStarted: (0, _evented.on)('init', function () {
      (0, _object.set)(this, 'keyboardActivated', true);
    })
  });

  _exports.default = _default;
});