define('ember-autoresize/ext/text-field', ['ember-autoresize/mixins/autoresize'], function (_autoresize) {
  'use strict';

  /**
    @element input
   */
  Ember.TextField.reopen(_autoresize.default, {

    /**
      By default, text fields only
      resize their width.
       @attribute shouldResizeWidth
      @default true
      @type Boolean
     */
    shouldResizeWidth: true,

    /**
      Whitespace should be treated as significant
      for text fields.
       @attribute significantWhitespace
      @default true
      @type Boolean
     */
    significantWhitespace: true,

    /**
      This provides a single character
      so users can click into an empty
      text field without it being too small
       If a placeholder is set,
      it will be used instead.
       @attribute autoResizeText
      @type String
     */
    autoResizeText: Ember.computed('value', 'placeholder', {
      get: function get() {
        var placeholder = Ember.get(this, 'placeholder');
        var value = Ember.get(this, 'value');

        if (Ember.isEmpty(value)) {
          return Ember.isEmpty(placeholder) ? '.' : placeholder;
        }

        return value;
      }
    })
  });
});