define("ember-redux/route", ["exports", "@ember/service", "@ember/routing/route"], function (_exports, _service, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var route = function route(funcs) {
    return function wrapWithRoute(IncomingRoute) {
      var WrappedRoute = IncomingRoute || _route.default;
      return WrappedRoute.extend({
        redux: (0, _service.inject)('redux'),
        init: function init() {
          var redux = this.get('redux');
          var route = this;
          Object.keys(funcs).forEach(function (func) {
            route[func] = function () {
              for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
                args[_key] = arguments[_key];
              }

              args.unshift(redux.dispatch.bind(redux));
              return funcs[func].apply(route, args);
            };
          });

          this._super.apply(this, arguments);
        }
      });
    };
  };

  var _default = route;
  _exports.default = _default;
});