define("ember-click-outside/mixin", ["exports", "@ember/object/mixin", "@ember/object", "ember-click-outside/utils"], function (_exports, _mixin, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable ember/no-new-mixins */
  var bound = function bound(fnName) {
    return (0, _object.computed)(fnName, function () {
      var fn = (0, _object.get)(this, fnName);

      if (fn) {
        // https://github.com/zeppelin/ember-click-outside/issues/1
        return fn.bind(this);
      }

      return;
    });
  };

  var _default = _mixin.default.create({
    clickOutside: function clickOutside() {},
    clickHandler: bound('outsideClickHandler'),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (!(0, _utils.ios)()) {
        return;
      }

      document.body.style.cursor = 'pointer';
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (!(0, _utils.ios)()) {
        return;
      }

      document.body.style.cursor = '';
    },
    outsideClickHandler: function outsideClickHandler(e) {
      var element = (0, _object.get)(this, 'element');
      var path = e.path || e.composedPath && e.composedPath();

      if (path) {
        path.includes(element) || this.clickOutside(e);
      } else {
        // Check if the click target still is in the DOM.
        // If not, there is no way to know if it was inside the element or not.
        var isRemoved = !e.target || !(0, _utils.documentOrBodyContains)(e.target); // Check the element is found as a parent of the click target.

        var isInside = element === e.target || element.contains(e.target);

        if (!isRemoved && !isInside) {
          this.clickOutside(e);
        }
      }
    },
    addClickOutsideListener: function addClickOutsideListener() {
      var eventType = this.eventType || 'click';
      var clickHandler = (0, _object.get)(this, 'clickHandler');
      document.addEventListener(eventType, clickHandler);
    },
    removeClickOutsideListener: function removeClickOutsideListener() {
      var eventType = this.eventType || 'click';
      var clickHandler = (0, _object.get)(this, 'clickHandler');
      document.removeEventListener(eventType, clickHandler);
    }
  });

  _exports.default = _default;
});