define("ember-web-workers/services/worker", ["exports", "@ember/debug", "@ember/array", "rsvp", "@ember/object", "@ember/service", "@ember/object/evented", "@ember/utils"], function (_exports, _debug, _array, _rsvp, _object, _service, _evented, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function messageListener(meta, event) {
    var ping = event.data === true; // Check if the worker has been instantiated via event listener:
    // worker.on('name', data, callback);

    if ((0, _object.get)(meta, 'keepAlive')) {
      var fn = (0, _object.get)(meta, 'callback');

      if (ping) {
        // A 'true' message tell us that the worker has been created correctly,
        // then resolve the promise returned by the event listener.
        this.trigger('resolve', meta);
      } else if (fn) {
        // The worker is sending data, call the fn this the event data.
        fn(event.data);
      } else {
        // Receiving data from a worker created via 'open'.
        this.trigger('resolve', meta, event.data);
      } // If the response is equals to 'true' we should ignore it because
      // the worker is pinging us to tell that everything is correct.

    } else if (!ping) {
      // Resolve the promise returned by the method 'postMessage' with the event data.
      this.trigger('resolve', meta, event.data);
    }
  }

  function errorListener(meta, error) {
    // An error has ocurrect, reject the promise and kill the worker.
    this.trigger('reject', meta, error.message);
  }

  var _default = _service.default.extend(_evented.default, {
    assetMap: (0, _service.inject)('asset-map'),

    /**
     * Check if workers are enabled.
     *
     * @property isEnabled
     * @type Boolean
     */
    get isEnabled() {
      return Boolean(window.Worker);
    },

    /**
       * Static workers file path.
       *
       * @property webWorkersPath
       * @type String
       */
    webWorkersPath: 'assets/web-workers/',

    /**
       * Initialize metadata array.
       *
       * @method init
       */
    init: function init() {
      this._super.apply(this, arguments); // Initialize metadata array, it will store all running workers with their own promises/callbacks.


      this.set('_cache', (0, _array.A)([]));
    },

    /**
       * Start a worker and attach the events given a name.
       *
       * @method _wakeup
     * @param String name
     * @param Function callback
     * @return Object
       */
    _wakeUp: function _wakeUp(name, callback) {
      var keepAlive = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      (false && !((0, _utils.isPresent)(name)) && (0, _debug.assert)('You must provide the worker name', (0, _utils.isPresent)(name))); // 'keepAlive' will store if the worker should still alive after sending a message.

      var workerUrl = this.get('assetMap').resolve("".concat(this.get('webWorkersPath')).concat(name, ".js"));
      var worker = new window.Worker(workerUrl);

      var deferred = _rsvp.default.defer('Worker: sending message');

      var meta = {
        keepAlive: keepAlive,
        worker: worker,
        name: name,
        deferred: deferred,
        callback: callback
      }; // Attach the worker events.

      worker.addEventListener('message', messageListener.bind(this, meta));
      worker.addEventListener('error', errorListener.bind(this, meta));
      return meta;
    },

    /**
       * Resolve pending promise.
       *
       * @method _onResolve
     * @param Object data
       */
    _onResolve: (0, _evented.on)('resolve', function (meta, data) {
      var deferred = (0, _object.get)(meta, 'deferred');

      if (!(0, _object.get)(meta, 'keepAlive')) {
        this._cleanMeta(meta);
      }

      deferred.resolve(data);
    }),

    /**
       * Reject pending promise.
       *
       * @method _onReject
     * @param Object meta
       */
    _onReject: (0, _evented.on)('reject', function (meta, error) {
      this._cleanMeta(meta);

      (0, _object.get)(meta, 'deferred').reject(error);
    }),

    /**
       * Clean request metadata & kill worker if neccessary.
       *
       * @method _cleanMeta
     * @param Object meta
       */
    _cleanMeta: function _cleanMeta(meta) {
      this._sleep((0, _object.get)(meta, 'worker'));

      this.get('_cache').removeObject(meta);
    },

    /**
       * Kill worker.
       *
       * @method _sleep
     * @param Worker worker
       */
    _sleep: function _sleep(worker) {
      worker.terminate();
    },

    /**
       * Cancel pending promise.
       *
       * @method terminate
     * @param Ember.RSVP promise
     */
    terminate: function terminate(promise) {
      var _cache = this.get('_cache');

      var index = _cache.length; // Reverse loop to prevent errors (this loop iterates a collection while deletes its items)

      while (index--) {
        var meta = _cache[index];
        var deferred = (0, _object.get)(meta, 'deferred'); // If promise exists reject it, if not reject all.

        if (deferred.promise === promise || !promise) {
          this.trigger('reject', meta);
        }
      }
    },

    /**
     * Send event to the worker and terminate it when responses.
     *
     * @method postMessage
      * @param String name
      * @param Object data
     * @return Mixed
     */
    postMessage: function postMessage(name, data) {
      (false && !(this.get('isEnabled')) && (0, _debug.assert)('Workers are disabled', this.get('isEnabled')));

      var meta = this._wakeUp(name);

      this.get('_cache').pushObject(meta);
      (0, _object.get)(meta, 'worker').postMessage(data);
      return (0, _object.get)(meta, 'deferred.promise');
    },

    /**
     * Suscribe to a worker.
     *
     * @method on
      * @param String name
      * @param Object data
      * @param Function callback
     */
    on: function on(name, callback) {
      (false && !(typeof callback === 'function') && (0, _debug.assert)('Cannot register an event with no callback', typeof callback === 'function'));

      var meta = this._wakeUp(name, callback, true);

      this.get('_cache').pushObject(meta);
      return (0, _object.get)(meta, 'deferred.promise');
    },

    /**
     * Suscribe to a worker.
     *
     * @method off
      * @param String name
      * @param Function callback
     */
    off: function off(name, callback) {
      var _this = this;

      var metaArray;

      if (callback) {
        (false && !(typeof callback === 'function') && (0, _debug.assert)('Callback should be a function', typeof callback === 'function'));
        var matchingWorker = this.get('_cache').find(function (meta) {
          return name === meta.name && callback === meta.callback;
        });
        metaArray = matchingWorker ? [matchingWorker] : [];
      } else {
        metaArray = this.get('_cache').filter(function (meta) {
          return name === meta.name;
        });
      }

      if (metaArray.length) {
        metaArray.forEach(function (meta) {
          return _this._cleanMeta(meta);
        });
        return _rsvp.default.resolve();
      }

      return _rsvp.default.reject('Worker: event does not exist');
    },

    /**
     * Start a worker.
     *
     * @method open
      * @param String name
     */
    open: function open(name) {
      var _this2 = this;

      var meta = this._wakeUp(name, null, true);

      var promise = (0, _object.get)(meta, 'deferred.promise').then(function () {
        return {
          postMessage: function postMessage(data) {
            var deferred = _rsvp.default.defer();

            var channel = new MessageChannel();

            channel.port2.onmessage = function (e) {
              return deferred.resolve(e.data);
            };

            (0, _object.get)(meta, 'worker').postMessage(data, [channel.port1]);
            return deferred.promise;
          },
          terminate: function terminate() {
            _this2._cleanMeta(meta);

            return _rsvp.default.resolve();
          }
        };
      });
      this.get('_cache').pushObject(meta);
      return promise;
    }
  });

  _exports.default = _default;
});