define("ember-simplebar/templates/components/simple-bar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "8tuoRFv7",
    "block": "[[[11,0],[24,0,\"ember-simplebar\"],[17,1],[4,[38,0],[[30,0,[\"args\"]]],[[\"onUpdate\"],[[28,[37,1],[[30,0,[\"setInstance\"]]],null]]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"simplebar-wrapper\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"simplebar-height-auto-observer-wrapper\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"simplebar-height-auto-observer\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"simplebar-mask\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"simplebar-offset\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"simplebar-content-wrapper\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"simplebar-content\"],[12],[1,\"\\n\"],[41,[30,0,[\"instance\"]],[[[1,\"              \"],[18,2,[[30,0,[\"instance\"]]]],[1,\"\\n\"]],[]],null],[1,\"          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"simplebar-placeholder\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"simplebar-track simplebar-horizontal\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"simplebar-scrollbar\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"simplebar-track simplebar-vertical\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"simplebar-scrollbar\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"-private/ember-simplebar-init\",\"fn\",\"if\",\"yield\"]]",
    "moduleName": "ember-simplebar/templates/components/simple-bar.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});