define("ember-file-upload/system/data-transfer", ["exports", "@ember/array", "@ember/object"], function (_exports, _array, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var getDataSupport = {};

  var _default = _object.default.extend({
    dataTransfer: null,
    itemDetails: null,
    queue: null,
    getData: function getData(type) {
      var dataTransfer = this.dataTransfer;

      if (getDataSupport[type] == null) {
        try {
          var data = dataTransfer.getData(type);
          getDataSupport[type] = true;
          return data;
        } catch (e) {
          getDataSupport[type] = false;
        }
      } else if (getDataSupport[type]) {
        return dataTransfer.getData(type);
      }
    },
    valid: (0, _object.computed)('dataTransfer.{files.length,items.length}', 'files.length', 'itemDetails.length', {
      get: function get() {
        var _this$dataTransfer, _this$dataTransfer$it, _this$dataTransfer2, _this$dataTransfer2$f, _this$itemDetails;

        if (this.files == null) {
          return true;
        }

        return (((_this$dataTransfer = this.dataTransfer) === null || _this$dataTransfer === void 0 ? void 0 : (_this$dataTransfer$it = _this$dataTransfer.items) === null || _this$dataTransfer$it === void 0 ? void 0 : _this$dataTransfer$it.length) || ((_this$dataTransfer2 = this.dataTransfer) === null || _this$dataTransfer2 === void 0 ? void 0 : (_this$dataTransfer2$f = _this$dataTransfer2.files) === null || _this$dataTransfer2$f === void 0 ? void 0 : _this$dataTransfer2$f.length) || ((_this$itemDetails = this.itemDetails) === null || _this$itemDetails === void 0 ? void 0 : _this$itemDetails.length)) === this.files.length;
      }
    }),
    files: (0, _object.computed)('dataTransfer.{files,items}', 'itemDetails', 'queue.{accept,multiple}', {
      get: function get() {
        var _this$dataTransfer3, _this$dataTransfer4, _this$queue, _this$queue2;

        var fileList = ((_this$dataTransfer3 = this.dataTransfer) === null || _this$dataTransfer3 === void 0 ? void 0 : _this$dataTransfer3.files) || null;
        var itemList = ((_this$dataTransfer4 = this.dataTransfer) === null || _this$dataTransfer4 === void 0 ? void 0 : _this$dataTransfer4.items) || null;
        var itemDetails = this.itemDetails;

        if (fileList == null && itemList || itemList != null && fileList != null && itemList.length > fileList.length) {
          fileList = itemList;
        }

        if (fileList == null && itemDetails || itemDetails != null && fileList != null && itemDetails.length > fileList.length) {
          fileList = itemDetails;
        }

        if (fileList == null) {
          return null;
        }

        var files = (0, _array.A)();

        if (!((_this$queue = this.queue) !== null && _this$queue !== void 0 && _this$queue.multiple) && fileList.length > 1) {
          files.push(fileList[0]);
        } else {
          for (var i = 0, len = fileList.length; i < len; i++) {
            files.push(fileList[i]);
          }
        }

        var accept = (_this$queue2 = this.queue) === null || _this$queue2 === void 0 ? void 0 : _this$queue2.accept;

        if (accept == null) {
          return files;
        }

        var tokens = (0, _array.A)(accept.split(',').map(function (token) {
          return token.trim().toLowerCase();
        }));
        var extensions = (0, _array.A)(tokens.filter(function (token) {
          return token.indexOf('.') === 0;
        }));
        var mimeTypeTests = (0, _array.A)((0, _array.A)(tokens.filter(function (token) {
          return token.indexOf('.') !== 0;
        })).map(function (mimeType) {
          return function (type) {
            if ((0, _array.A)(['audio/*', 'video/*', 'image/*']).includes(mimeType)) {
              return type.split('/')[0] === mimeType.split('/')[0];
            } else {
              return type === mimeType;
            }
          };
        }));
        return files.filter(function (file) {
          var extension = null;

          if (file.name && /(\.[^.]+)$/.test(file.name)) {
            extension = file.name.toLowerCase().match(/(\.[^.]+)$/)[1];
          }

          var type = file.type.toLowerCase();
          return mimeTypeTests.find(function (mimeTypeTest) {
            return mimeTypeTest(type);
          }) || extensions.indexOf(extension) !== -1;
        });
      }
    })
  });

  _exports.default = _default;
});