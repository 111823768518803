define("@zestia/ember-select-box/utils/select-box/option/activate", ["exports", "@zestia/ember-select-box/utils/component/filter", "@zestia/ember-select-box/utils/component/scroll-into-view"], function (_exports, _filter, _scrollIntoView) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports._activateOption = _activateOption;
  _exports.activateOption = activateOption;
  _exports.deactivateOptions = deactivateOptions;
  _exports.activateOptionAtIndex = activateOptionAtIndex;
  _exports.activateNextOption = activateNextOption;
  _exports.activatePreviousOption = activatePreviousOption;
  _exports.activateOptionForValue = activateOptionForValue;
  _exports.activateOptionForKeyCode = activateOptionForKeyCode;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var fromCharCode = String.fromCharCode;

  function _activateOption(option) {
    var _option$args$_onActiv, _option$args;

    if (option.args.selectBox.activeOption === option) {
      return;
    }

    (_option$args$_onActiv = (_option$args = option.args)._onActivate) === null || _option$args$_onActiv === void 0 ? void 0 : _option$args$_onActiv.call(_option$args, option);
  }

  function activatedOption(option) {
    var _option$args$onActiva, _option$args2;

    (_option$args$onActiva = (_option$args2 = option.args).onActivate) === null || _option$args$onActiva === void 0 ? void 0 : _option$args$onActiva.call(_option$args2, option.value, option.args.selectBox.api);
  }

  function activateOption(selectBox, option, config) {
    activateOptionAtIndex(selectBox, option.index, config);
  }

  function deactivateOptions(selectBox) {
    _setActiveOptionIndex(selectBox, -1);
  }

  function focusOption(option) {
    option.element.focus();
  }

  function _setActiveOptionIndex(selectBox, index) {
    selectBox.activeOptionIndex = index;
  }

  function setActiveOptionIndex(selectBox, index) {
    var under = index < 0;
    var over = index > selectBox.option.length - 1;

    if (under || over) {
      return;
    }

    _setActiveOptionIndex(selectBox, index);
  }

  function activateOptionAtIndex(selectBox, index, config) {
    setActiveOptionIndex(selectBox, index);
    var option = selectBox.activeOption;

    if (!option) {
      return;
    }

    (0, _scrollIntoView.maybeScrollIntoView)(option, config);
    focusOption(option);
    activatedOption(option);
  }

  function activateNextOption(selectBox, config) {
    activateOptionAtIndex(selectBox, selectBox.activeOptionIndex + 1, _objectSpread({
      scrollIntoView: true
    }, config));
  }

  function activatePreviousOption(selectBox, config) {
    activateOptionAtIndex(selectBox, selectBox.activeOptionIndex - 1, _objectSpread({
      scrollIntoView: true
    }, config));
  }

  function activateOptionForValue(selectBox, value, config) {
    var option = selectBox.option.find(function (option) {
      return option.value === value;
    });

    if (!option) {
      return;
    }

    activateOption(selectBox, option, _objectSpread({
      scrollIntoView: true
    }, config));
  }

  function activateOptionForKeyCode(selectBox, keyCode, config) {
    var char = fromCharCode(keyCode);

    if (!char) {
      return;
    }

    var option = optionForChar(selectBox, char);

    if (!option) {
      return;
    }

    activateOption(selectBox, option, _objectSpread({
      scrollIntoView: true
    }, config));
  }

  function optionForChar(selectBox, char) {
    var _selectBox$charState;

    var prev = (_selectBox$charState = selectBox.charState) !== null && _selectBox$charState !== void 0 ? _selectBox$charState : {
      chars: '',
      index: 0
    };
    var prevChar = prev.chars.substring(prev.chars.length - 1);
    var repeatedChar = char === prevChar;
    var chars = "".concat(prev.chars).concat(char);
    var registeredOptions = selectBox.option;
    var options = (0, _filter.filterComponentsByTextContent)(registeredOptions, chars);
    var index = 0;
    var option = null;

    if (repeatedChar) {
      index = prev.index + 1;
      options = (0, _filter.filterComponentsByTextContent)(registeredOptions, prevChar);
      option = options[index];
    }

    if (!option) {
      index = 0;
      option = options[index];
    }

    if (prev.timer) {
      clearTimeout(prev.timer);
    }

    var timer = setTimeout(function () {
      return selectBox.charState = null;
    }, 1000);
    var next = {
      chars: chars,
      index: index,
      timer: timer
    };
    selectBox.charState = next;
    return option;
  }
});