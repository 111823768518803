define("ember-click-outside/components/click-outside", ["exports", "ember-click-outside/component", "ember-click-outside/utils"], function (_exports, _component, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  (0, _utils.printConsoleMessage)("Importing 'ember-click-outside/components/click-outside' is deprecated. Please import from 'ember-click-outside/component' instead.");
  var _default = _component.default;
  _exports.default = _default;
});