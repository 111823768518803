define("ember-keyboard/utils/sort", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.compare = compare;
  _exports.compareProp = compareProp;
  _exports.reverseCompareProp = reverseCompareProp;

  function compare(a, b) {
    var diff = a - b;
    return (diff > 0) - (diff < 0);
  }

  function compareProp(a, b, propName, convertValue) {
    return compare(convertValue ? convertValue((0, _object.get)(a, propName)) : (0, _object.get)(a, propName), convertValue ? convertValue((0, _object.get)(b, propName)) : (0, _object.get)(b, propName));
  }

  function reverseCompareProp(a, b, propName) {
    var convertValue = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
    return compareProp(b, a, propName, convertValue);
  }
});